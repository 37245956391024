<template>
  <cw-page
    icon="warning"
    class="cw-business-loan-rejected"
  >
    <template #hero-title>
      <translate>
        The application was suspended
      </translate>
    </template>
    <template #hero-text>
      <translate>
        Unfortunately, we are unable to grant a Saldo Corporate Loan.
      </translate>
    </template>
    <v-row>
      <v-col
        class="
          cw-border-container
          cw-border-container--primary
        "
        cols="12"
        md="8"
      >
        <p class="ma-0">
          <translate>
            The loan application has been suspended because unfortunately we are unable to grant
            your company a Saldo Corporate Loan at this time.
          </translate>
        </p>
      </v-col>
    </v-row>
  </cw-page>
</template>

<script>
export default {
  name: 'CwBusinessLoanRejected',
};
</script>
